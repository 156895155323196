import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NzConfigService} from "ng-zorro-antd/core/config";

@Component({
    selector: 'weni-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
    title = 'weni-backoffice';
    @ViewChild('IconChevronUp', {static: true}) iconChevronUp!: TemplateRef<void>;
    @ViewChild('IconCalendar', {static: true}) iconCalendar!: TemplateRef<void>;

    constructor(private readonly nzConfigService: NzConfigService) {
    }

    ngOnInit(): void {
        this.nzConfigService.set('select', {nzSuffixIcon: this.iconChevronUp});
        this.nzConfigService.set('datePicker', {nzSuffixIcon: this.iconCalendar});
    }


}
