<ng-template #IconUserCupertinos>
  <img alt="user" src="/assets/icons/search.svg" />
</ng-template>
<nz-layout>
  <nz-sider
    [nzCollapsed]="isOpenNavbar"
    nzBreakpoint="md"
    nzTheme="light"
    nzWidth="260px"
    [nzCollapsedWidth]="0"
  >
    <div class="content-sider">
      <div class="head-content-sider weni-mb-1">
        <div class="container-img-logo">
          <img
            alt="weni-logo"
            class="weni-w-100 weni-h-100 object-fit-contain"
            src="/assets/weni-logo.png"
          />
        </div>
        <nz-space
          class="nz-space weni-w-100 weni-my-1 weni-mb-0"
          nzDirection="horizontal"
          nzSize="middle"
          nzWrap
        >
          <nz-avatar
            *nzSpaceItem
            nzIcon="user"
            nzShape="circle"
            nzSize="large"
          ></nz-avatar>
          <div *nzSpaceItem class="weni-d-flex weni-flex-col">
            <h3 class="name-user">Weni Admin</h3>
            <span class="profile-user">Administrateur</span>
          </div>
          <span *nzSpaceItem>
            <a [nzDropdownMenu]="menu" nz-dropdown>
              <img
                alt="chevron"
                class="weni-w-100 weni-h-100 object-fit-contain"
                src="/assets/icons/chevron-up.svg"
              />
            </a>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu nzSelectable>
                <li nz-menu-item (click)="goMyProfil()">Mon profil</li>
              </ul>
            </nz-dropdown-menu>
          </span>
        </nz-space>
      </div>

      <div class="menu-content-sider">
        <div class="meu">
          <ng-container *ngFor="let menu of menus">
            <weni-item-menu
              (click)="onRedirectTo(menu)"
              [nzActive]="menu.nzActive"
              [nzLabel]="menu.nzLabel"
              [nzOpen]="menu.nzOpen"
              [nzSourceImage]="menu.nzSourceImage"
              [nzSubmenu]="menu.nzSubmenu"
            >
            </weni-item-menu>
            <weni-item-submenu
              [ngClass]="[
                menu.nzOpen && menu.nzSubmenu
                  ? 'visible-submenu'
                  : 'hide-submenu'
              ]"
              [nzSubmenuItem]="menu.nzSubmenuItem"
            ></weni-item-submenu>
          </ng-container>
          <nz-divider></nz-divider>
          <weni-item-menu
            (click)="goParameter()"
            [nzSourceImage]="'setting.svg'"
            nzLabel="Paramètres"
          ></weni-item-menu>
          <nz-divider></nz-divider>

          <div class="weni-p-1 weni-w-100">
            <button
              nzType="primary"
              nzDanger
              nz-button
              nzBlock
              nzShape="round"
              nzSize="large"
              (click)="logout()"
            >
              <span nz-icon nzType="logout"></span>
              Me déconnecter
            </button>
          </div>
        </div>
      </div>
    </div>
  </nz-sider>
  <nz-layout>
    <nz-content>
      <div
        class="top-bar weni-p-1 weni-mb-1 weni-d-flex weni-items-center weni-jc-between weni-gap-1"
      >
        <div class="weni-d-flex weni-items-center weni-gap-0">
          <ng-container *ngIf="!isOpenNavbar">
            <span
              (click)="toggleNavbar()"
              nz-icon
              class="menu-icon"
              nzType="menu-fold"
              nzTheme="outline"
            ></span
          ></ng-container>
          <ng-container *ngIf="isOpenNavbar">
            <span
              (click)="toggleNavbar()"
              nz-icon
              nzType="menu-unfold"
              nzTheme="outline"
              class="menu-icon"
            ></span>
            ></ng-container
          >

          <div class="top-search-bar">
            <nz-form-control nzErrorTip="">
              <nz-input-group
                [nzSuffix]="IconUserCupertinos"
                class="weni-input-field-form"
                id="rechercher"
                (input)="onInput($event)"
                nzSize="large"
              >
                <input nz-input nzSize="large" placeholder="Recherche..." />
              </nz-input-group>
            </nz-form-control>
          </div>
        </div>

        <div class="weni-d-flex weni-gap-1" style="margin-right: 25px">
          <div class="bell-container" (click)="goNotifications()">
            <span class="bell-value">
              <span class="text">0</span>
            </span>
            <span nz-icon nzType="bell" class="bell" nzTheme="outline"></span>
          </div>
        </div>
      </div>

      <div class="content-page">
        <ng-container *ngIf="isPageChange">
          <weni-page-loading-widget></weni-page-loading-widget>
        </ng-container>
        <ng-container *ngIf="!isPageChange">
          <div class="mb-1" style="margin-bottom: 1rem !important">
            <nz-breadcrumb
              class="weni-mb-1"
              nzAutoGenerate
              nzSeparator="/"
            ></nz-breadcrumb>
          </div>
          <div class="page">
            <router-outlet></router-outlet>
          </div>
        </ng-container>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
