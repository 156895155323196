import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemMenuComponent } from './item-menu/item-menu.component';
import {NzFormModule} from "ng-zorro-antd/form";
import { ItemSubmenuComponent } from './item-submenu/item-submenu.component';
import {TableBodyItemComponent} from "./table-body-item/table-body-item.component";



@NgModule({
  declarations: [
    ItemMenuComponent,
    ItemSubmenuComponent,
    TableBodyItemComponent
  ],
    imports: [
        CommonModule,
        NzFormModule
    ],
  exports: [
    ItemMenuComponent,
    ItemSubmenuComponent,
    TableBodyItemComponent
  ]
})
export class ItemsModule { }
