import { Component } from '@angular/core';

@Component({
  selector: 'weni-layout-auth',
  templateUrl: './layout-auth.component.html',
  styleUrls: ['./layout-auth.component.less']
})
export class LayoutAuthComponent {

}
