import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaneFilterComponent } from './pane-filter/pane-filter.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzFormModule } from 'ng-zorro-antd/form';
import { ChipComponent } from './chip/chip.component';
import { TableComponent } from './table/table.component';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { FormsModule } from '@angular/forms';
import { TableHeadComponent } from './table-head/table-head.component';
import { TableBodyComponent } from './table-body/table-body.component';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { PaginationComponent } from './pagination/pagination.component';
import { FileUploadButtonComponent } from './file-upload-button/file-upload-button.component';
import { EngineFileUploadComponent } from './engine-file-upload/engine-file-upload.component';
import { NotificationDropdownComponent } from './notification-dropdown/notification-dropdown.component';

@NgModule({
  declarations: [
    PaneFilterComponent,
    ChipComponent,
    TableComponent,
    TableHeadComponent,
    TableBodyComponent,
    PaginationComponent,

    FileUploadButtonComponent,
    EngineFileUploadComponent,
    NotificationDropdownComponent,
  ],
  imports: [
    CommonModule,
    NzInputModule,
    NzSpaceModule,
    NzSegmentedModule,
    NzButtonModule,
    NzIconModule,
    NzFormModule,
    NzCheckboxModule,
    FormsModule,
    NzPaginationModule,
  ],
  exports: [
    PaneFilterComponent,
    ChipComponent,
    TableComponent,
    TableHeadComponent,
    TableBodyComponent,
    PaginationComponent,
    FileUploadButtonComponent,
    EngineFileUploadComponent,
  ],
})
export class WidgetsModule {}
