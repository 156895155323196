<div [ngClass]="{'weni-menu-active': active}" class="weni-item-menu weni-d-flex weni-flex-row weni-items-center">
    <div class="prefixIcon">
        <img alt="icon-image" src="assets/icons/{{sourceImage}}">
    </div>
    <div class="content-label">
        <span class="label">{{label}}</span>
    </div>
    <div *ngIf="isSubmenu" [ngClass]="{'rotate-bottom': openSubmenu}" class="surfixIcon">
        <img alt="chevron-up" src="/assets/icons/chevron-up.svg">
    </div>
</div>
