import { Component } from '@angular/core';

@Component({
  selector: 'weni-shipping-spaces',
  templateUrl: './shipping-spaces.component.html',
  styleUrls: ['./shipping-spaces.component.less']
})
export class ShippingSpacesComponent {

}
