import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { mappingRoutes } from '../mappingRoutes';
import { Observable } from 'rxjs';
import { UtilsService } from '../services/utils.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private utilsService: UtilsService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    // Check if the user is logged in
    if (this.utilsService.hasToken()) {
      return true;
    }

    // Not logged in, redirect to the login page with the return URL
    this.redirectUser(state);
    return false;
  }

  private redirectUser(state: RouterStateSnapshot) {
    // Redirect to the login page with the return URL as a query parameter
    this.router
      .navigate([mappingRoutes.auth.login], {
        queryParams: { returnUrl: state.url },
      })
      .then((navigate: boolean) => {
        if (navigate) {
          this.utilsService.RemoveToken();
        }
      });
  }
}
