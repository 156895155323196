import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ISubmenu } from '../../../core/interfaces/ISubmenu';

@Component({
  selector: 'weni-item-submenu',
  templateUrl: './item-submenu.component.html',
  styleUrls: ['./item-submenu.component.less'],
})
export class ItemSubmenuComponent {
  @Input('nzSubmenuItem') submenu: ISubmenu[] = [];

  constructor(private _router: Router) {}

  onRedirectTo(item: ISubmenu) {
    this.submenu = this.submenu.map((value) => {
      return {
        ...value,
        active: item.label == value.label,
      };
    });
    if (item.pathRedirect) this._router.navigate([item.pathRedirect]);
  }
}
