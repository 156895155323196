import { Component } from '@angular/core';

@Component({
  selector: 'weni-page-loading-widget',
  templateUrl: './page-loading-widget.component.html',
  styleUrls: ['./page-loading-widget.component.less']
})
export class PageLoadingWidgetComponent {

}
