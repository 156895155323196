import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutAdminComponent } from './layout-admin/layout-admin.component';
import { LayoutFormComponent } from './layout-form/layout-form.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ItemsModule } from '../shared/items/items.module';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { LayoutAuthComponent } from './layout-auth/layout-auth.component';
import { WidgetsModule } from '../shared/widgets/widgets.module';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { FormsModule } from '@angular/forms';
import { PageLoadingWidgetComponent } from './page-loading-widget/page-loading-widget.component';

@NgModule({
  declarations: [
    LayoutAdminComponent,
    LayoutFormComponent,
    LayoutAuthComponent,
    PageLoadingWidgetComponent,
  ],
  exports: [],
  imports: [
    CommonModule,
    NzLayoutModule,
    RouterOutlet,
    NzSpaceModule,
    NzAvatarModule,
    NzDropDownModule,
    NzIconModule,
    ItemsModule,
    NzDividerModule,
    NzInputModule,
    NzSegmentedModule,
    NzButtonModule,
    NzBreadCrumbModule,
    NzTabsModule,
    WidgetsModule,
    RouterLinkActive,
    NzInputModule,
    NzFormModule,
    RouterModule,
    NzAutocompleteModule,
    FormsModule,
  ],
})
export class LayoutsModule {}
